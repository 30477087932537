<template>

    <!-- 长篇 弹窗 -->
    <el-dialog
      class="custom-dialog new-changpian-dialog"
      ref="dialog"
      :visible.sync="showChangpianDialogFlag"
      :append-to-body="true"
      :modal-append-to-body="false"
      :close-on-press-escape="false"
      :withHeader="false"
      :destroy-on-close="true"
      :show-close="false"
      :close-on-click-modal="false"
      :wrapperClosable="false"
      :width="drawerWidth"
      :top="dialogTop"
      @close="close"
      >
      <el-form label-position="top">
        <el-row type="flex" align="middle">
          <el-button v-if="!story.id" icon="el-icon-arrow-left" circle @click="$router.back()" style="margin-right: 15px"></el-button>
          <h3>{{dialogTitle}}</h3>
        </el-row>
        <div v-if="step1Visable">
          <el-row type="flex" justify="space-between">
            <el-col :span="dialogStyle.colSpan">
              <el-form-item label="写作视角" required>
                  <el-radio-group v-model="formData.writingPerspective">
                    <el-radio :border="false" :label="item.name" :key="index" v-for="(item,index) in enums.WritingPerspectiveEnum">{{item.desc}}</el-radio>
                  </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="dialogStyle.colSpan" :offset="dialogStyle.colOffset" v-if="enums.ArticleTypeEnum && enums.ArticleTypeEnum.length>0">
              <el-form-item label="故事类型" required>
<!--                <el-select
                    v-model="formData.articleType"
                    filterable
                    size="mini"
                    placeholder="请选择故事类型">
                  <el-option
                    v-for="(item,index) in enums.ArticleTypeEnum.filter((item) => {
                    return item.code > 100 && item.code < 200
                  })"
                    :label="item.desc"
                    :key="index"
                    :value="item.name"
                  />
                </el-select> -->

                <el-select
                    v-model="formData.articleTypeId"
                    filterable
                    size="mini"
                    placeholder="请选择故事类型">
                  <el-option
                    :style="{ 'padding-left': (item.depth+1) + 'rem' }"
                    v-for="(item,index) in $treeToList(storyTiaojian.filter(item1=>item1.code==1)[0].children.filter(item2=>item2.code==9)[0].children)"
                    :label="item.desc"
                    :key="index"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mgt-10" type="flex" justify="space-between">
            <el-col :span="dialogStyle.colSpan" v-if="enums.ArticleStyleEnum">
              <el-form-item label="故事文风" required>
<!--                <el-select
                  v-model="formData.articleStyle"
                  filterable
                  size="mini"
                  placeholder="请选择文风">
                  <el-option
                    v-for="(item,index) in enums.ArticleStyleEnum.filter((item) => {
                      return item.code != 3
                    })"
                    :label="item.desc"
                    :key="index"
                    :value="item.name"
                  />
                </el-select> -->

                <el-select
                    v-model="formData.articleStyleId"
                    filterable
                    size="mini"
                    placeholder="请选择文风">
                  <el-option
                    :style="{ 'padding-left': (item.depth+1) + 'rem' }"
                    v-for="(item,index) in $treeToList(storyTiaojian.filter(item1=>item1.code==5)[0].children)"
                    :label="item.desc"
                    :key="index"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="dialogStyle.colSpan" :offset="dialogStyle.colOffset">
              <el-form-item label="故事时代" required>
<!--                <el-select
                    v-model="formData.articleAge"
                    filterable
                    size="mini"
                    placeholder="请选择时代">
                  <el-option
                    v-for="(item,index) in enums.ArticleAgeEnum"
                    :label="item.desc"
                    :key="index"
                    :value="item.name"
                  />
                </el-select> -->

                <el-select
                    v-model="formData.articleAgeId"
                    filterable
                    size="mini"
                    placeholder="请选择时代">
                  <el-option
                    :style="{ 'padding-left': (item.depth+1) + 'rem' }"
                    v-for="(item,index) in $treeToList(storyTiaojian.filter(item1=>item1.code==4)[0].children)"
                    :label="item.desc"
                    :key="index"
                    :value="item.code"
                  />
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row class="mgt-10">
            <el-form-item label="故事背景" required>
              <el-input type="textarea" placeholder="请描述你想创作的故事…可以是简单的一句话或者是一大段设定"
                v-model="formData.storyBg" :rows="3" maxlength="1000" show-word-limit clearable class="story-idea">
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="mgt-10">
            <el-form-item label="故事人设" required>
              <el-input type="textarea" placeholder="请输入故事人物设定"
                v-model="formData.storyCp" :rows="3" maxlength="2000" show-word-limit clearable class="story-idea">
              </el-input>
            </el-form-item>
          </el-row>
        </div>
        <div v-if="step2Visable" class="mgt-10" style="background: #F2F3FF;border-radius: 16px;padding: 0 1rem 1rem 1rem;">
          <el-row>
            <el-form-item label="从第几章开始创作?" required>
              <el-input type="number" min="1" placeholder="请输入数字序号" @change="startOutlineNumberChange"
                v-model="formData.startOutlineNumber" style="width: 200px;">
                <template slot="prepend">第</template>
                <template slot="append">章</template>
              </el-input>
            </el-form-item>
          </el-row>
          <el-row class="mgt-10">
            <el-form-item :label="'请输入第'+(formData.startOutlineNumber || '')+'章大纲'" required>
              <el-input type="textarea" v-model="formData.startOutline" :rows="3" maxlength="1000" show-word-limit clearable class="story-idea no-border-input"></el-input>
            </el-form-item>
          </el-row>
          <el-row class="mgt-10" v-if="formData.startOutlineNumber && formData.startOutlineNumber>1">
            <el-form-item :label="`请输入你已创作的第${formData.startOutlineNumber?formData.startOutlineNumber-1:''}章正文内容`" required>
              <el-input type="textarea"
                v-model="formData.preContent" :rows="3" maxlength="4000" show-word-limit clearable class="story-idea no-border-input">
              </el-input>
            </el-form-item>
          </el-row>
        </div>
      </el-form>
      <div slot="footer" class="dialog-footer">
          <div v-if="story.id" type="flex" justify="center" style="display: flex;justify-content: center;">
            <el-button style="padding: 10px 55px" @click="close()">取 消</el-button>
            <el-button style="padding: 10px 55px;margin-left: 30px" type="primary" @click="newStoryByIdea">保 存</el-button>
          </div>
          <div v-else>
            <div v-if="dialogStyle.currentStep == 1" style="display: flex;justify-content: center;">
              <el-button :disabled="!btnActiveFlag" style="padding: 10px 55px;margin-left: 30px" type="primary" @click="goStep2">下一步</el-button>
            </div>
            <div v-else style="display: flex;justify-content: center;">
              <el-button style="padding: 10px 55px" @click="goStep1">返回上一步</el-button>
              <el-button style="padding: 10px 55px;margin-left: 30px" type="primary" @click="newStoryByIdea">确 定</el-button>
            </div>
          </div>
        </div>
    </el-dialog>
</template>
<script>
import * as StoryCpApi from '@/api/storycp'
import * as StorySynesthesiaElementApi from '@/api/storysynesthesiaelement'
import * as StoryHotApi from '@/api/storyhot'
import { mapState, mapMutations} from 'vuex'
import * as ArticleApi from '@/api/article'
export default {
  name: 'StoryByIdea',
  props: {
    enums: {
      type: Object,
      default: {ArticleTypeEnum: []}
    },
    channel: {
      type: String,
      default: 'workspace_index'
    },
  },
  components: {

  },
  data() {
    return {
      sliderBoxActive: 0,
      synesthesiaElements:[],
      cps:[],
      hots:[],
      formData: {
        writingPerspective: 'THIRD_PERSON',
        articleType: 'CP_YANQING',
        articleStyle: 'ZHENGWEN',
        articleAge: 'XIANDAI',
        articleTypeId: '',
        articleStyleId: '',
        articleAgeId: '',
        storyBg: '',
        storyCp: '',
        startOutlineNumber: 1,
        startOutline: '',
        preContent: '',
      },
      rules: {
        picUrl: [
          { required: true, message: '请选择文件', trigger: 'blur' }
        ]
      },
      step1Visable: true,
      step2Visable: false,
      dialogStyle: {
        colSpan: 10,
        colOffet: 2,
        totalStep: 2,
        currentStep: 1
      }
    }
  },
  mounted() {
    console.log('changpian dialog mounted');
    console.log(this.story);

    this.handleIdeaRef('element')
    this.handleIdeaRef('hot')
    this.handleIdeaRef('cp')

    window.addEventListener('resize', this.forceUpdate);

    if(this.story.id){
      this.formData.writingPerspective = this.story.writingPerspective
      this.formData.articleType = this.story.articleType
      this.formData.articleStyle = this.story.articleStyle
      this.formData.articleAge = this.story.articleAge
      this.formData.storyBg = this.story.storyBg
      this.formData.storyCp = this.story.storyCp
      this.formData.startOutlineNumber = this.story.startOutlineNumber
      this.formData.startOutline = this.story.startOutline
      this.formData.preContent = this.story.preContent
      // 动态知识条件
      this.formData.articleTypeId = this.story.articleTypeId
      this.formData.articleStyleId = this.story.articleStyleId
      this.formData.articleAgeId = this.story.articleAgeId
    }

    this.$nextTick(() => {
      if ('workspace_index' == this.channel) {// 初始化表单
        this.sliderBoxActive = 0
        console.log('StoryIdeaDialog init')
        this.setStory({id: '', articleType: 'TANBI_DOUBLE_BOY', writingPerspective: 'FIRST_PERSON', storyCp: '', storyHot: '', storyIdeaElement: ''})
      }
    })
  },
  computed: {
    ...mapState(['user', 'story', 'showChangpianDialogFlag', 'storyTiaojian']),
    drawerWidth() {
      return window.innerWidth <= 768 ? '100%' : window.innerWidth <= 1200 ? '80%' : '60%';
    },
    dialogTop() {
      return window.innerWidth <= 768 ? "0" : '10vh';
    },
    dialogTitle () {
      return this.story.id ? '故事设置' : '新建故事'
    },
    showHeader() {
      return window.innerWidth <= 768
    },
    btnActiveFlag() {
      return (this.formData.storyCp && this.formData.storyCp.length>0)
      && (this.formData.storyBg && this.formData.storyBg.length>0)
      && (this.formData.articleTypeId && Number(this.formData.articleTypeId)>0)
      && (this.formData.articleStyleId && Number(this.formData.articleStyleId)>0)
      && (this.formData.articleAgeId && Number(this.formData.articleAgeId)>0)
    },
  },
  created() {
    console.log('created');
  },
  methods: {
    ...mapMutations(['setStory', 'setShowChangpianDialogFlag']),
    close(){
      this.setShowChangpianDialogFlag(false);
    },
    newStoryByIdea() {
      if (!this.formData.writingPerspective || !this.formData.writingPerspective.trim()) {
          this.$message.error('请选择写作视角');
          return false;
      }
      if (!this.formData.articleTypeId || !this.formData.articleTypeId.trim() || Number(this.formData.articleTypeId)==0) {
          this.$message.error('请选择故事类型');
          return false;
      }
      if (!this.formData.articleStyleId || !this.formData.articleStyleId.trim() || Number(this.formData.articleStyleId)==0) {
          this.$message.error('请选择故事文风');
          return false;
      }
      if (!this.formData.articleAgeId || !this.formData.articleAgeId.trim() || Number(this.formData.articleAgeId)==0) {
          this.$message.error('请输入故事时代');
          return false;
      }
      if (!this.formData.storyBg || !this.formData.storyBg.trim()) {
          this.$message.error('请输入故事背景');
          return false;
      }
      if (!this.formData.storyCp || !this.formData.storyCp.trim()) {
          this.$message.error('请输入故事人设');
          return false;
      }
      if (!this.formData.startOutline || !this.formData.startOutline.trim()) {
        if(!this.story){
          this.$message.error('请输入故事起始大纲');
          return false;
        }
      }
      if (!this.formData.startOutlineNumber) {
        if(!this.story){
          this.$message.error('请输入故事起始大纲序号');
          return false;
        }
      }
      if (!this.formData.preContent || !this.formData.preContent.trim()) {
          if(!this.story){
            this.$message.error('请输入上一章故事正文');
            return false;
          }
      }

      this.story.writingPerspective = this.formData.writingPerspective
      this.story.articleType = this.formData.articleType
      this.story.articleStyle = this.formData.articleStyle
      this.story.articleAge = this.formData.articleAge
      this.story.storyBg = this.formData.storyBg
      this.story.storyCp = this.formData.storyCp
      this.story.startOutlineNumber = this.formData.startOutlineNumber
      this.story.startOutline = this.formData.startOutline
      this.story.preContent = this.formData.preContent
      // 动态知识库
      this.story.articleTypeId = this.formData.articleTypeId
      this.story.articleStyleId = this.formData.articleStyleId
      this.story.articleAgeId = this.formData.articleAgeId
      this.story.articleTagId = 0

      if(this.story.id){
        ArticleApi.update(this.story).then(res => {
            if(res.data.code == 0){
              console.log('update cp story')
              this.setStory(this.story)
              this.close()
              this.$message.success('修改成功');
            }else{
              this.$message.error(res.data.msg);
            }
        })
      }else{
        this.story.fromType = 'writer_client_cp'
        ArticleApi.create(this.story).then(res => {
            if(res.data.code == 0){
              let data = res.data.data
              console.log('create cp story')
              this.setStory(data)
              this.close()
              this.goStep1()
              this.$message.success('创建成功');
              this.$parent.handlePageChapter(1)
            }else{
              this.$message.error(res.data.msg);
            }
        })
      }
    },
    handleIdeaRef(flag) {
      if(flag == 'element') {
        StorySynesthesiaElementApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.synesthesiaElements = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }else if(flag == 'hot') {
        StoryHotApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.hots = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }else if(flag == 'cp') {
        StoryCpApi.page({size: 100}).then(res => {
          let result = res.data.data;
          const shuffledArray = this.shuffleArray([...result.records])
          this.cps = shuffledArray.slice(0, 4);
          // this.synesthesiaElements = result.records
        });
      }
    },
    handleIdeaClick(flag, text) {
      console.log('flag, text', flag, text);
      if(flag == 'element') {
        if(!this.story.storyIdeaElement){
          this.story.storyIdeaElement = text
        }else if(this.story.storyIdeaElement.indexOf(text) == -1){
          this.story.storyIdeaElement = this.story.storyIdeaElement +  ',' + text
        }
      }else if(flag == 'hot') {
        if(!this.story.storyHot){
          console.log('flag, text', flag, text);
          this.story.storyHot = text
          console.log('storyHot', this.story.storyHot);
          this.setStory(this.story)
        }else if(this.story.storyHot.indexOf(text) == -1){
          console.log('flag, text', flag, text);
          this.story.storyHot = this.story.storyHot +  ',' + text
        }
      }else if(flag == 'cp') {
        if(!this.story.storyCp){
          this.story.storyCp = text
        }else if(this.story.storyCp.indexOf(text) == -1){
          this.story.storyCp = this.story.storyCp +  ',' + text
        }
      }
    },
    shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
            // 生成从0到i的随机索引
            const j = Math.floor(Math.random() * (i + 1));
            // 交换元素
            [array[i], array[j]] = [array[j], array[i]];
        }
        return array;
    },
    forceUpdate() {
      this.$forceUpdate(); // 强制重新渲染，以更新计算属性
    },
    goStep1() {
      this.step1Visable = true
      this.step2Visable = false
      this.dialogStyle.currentStep = 1
    },
    goStep2() {
      this.step1Visable = false
      this.step2Visable = true
      this.dialogStyle.currentStep = 2
      console.log(this.formData)
    },
    startOutlineNumberChange() {
      this.formData.startOutlineNumber = parseInt(this.formData.startOutlineNumber)
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.forceUpdate);
  },
}
</script>

<style scoped lang="scss">
$tagMT: .5rem;
/deep/ .el-dialog__header {
  padding: 0;
}
/deep/ .story-idea .el-textarea__inner{
  // background: #F4F4F4;
  padding: 1rem;
}
/deep/ .el-form--label-top .el-form-item__label {
  padding: 0;
  font-weight: normal;
}
.story-idea-tag-box {
  margin-top: $tagMT;
  &-tag {
    display: inline-block;
    background: #F2F3FF;
    border-radius: 12px;
    font-weight: 400;
    font-size: 12px;
    color: #242424;
    padding: .25rem 1rem;
    margin: 0 .5rem .5rem 0;
    white-space: nowrap;
  }
}
.get-more {
  font-size: 12px;
  color: #0052D9;
  white-space: nowrap;
  margin-top: $tagMT;
}
.field-title {
  color: rgba(0,0,0,0.5);
}
</style>
